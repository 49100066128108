import React, { useEffect, useState, useRef } from "react";
// import { tranding_category_filter } from "../../data/categories_data";
import CategoryItem from "./categoryItem";
import { useSelector, useDispatch } from "react-redux";
import { fetchTokensData } from "../../redux/data/tokenSlice";
import { updateTrendingCategoryItemData } from "../../redux/counterSlice";
import { setItemsData } from "../../redux/itemDataSlice";

// import Collection_dropdown from "../../components/dropdown/collection_dropdown";
// import Image from "next/image";
// import UserDropdown from "../../components/dropdown/user_category_dropdown";
// import { productDataFilter } from "../../data/product_data.js";
// import LoadingCards from "../loading/LoadingCards.jsx";

const Trending_categories_items = ({
  user,
  wallet,
  filter,
  balanceInfo,
  userMap,
}) => {
  const dispatch = useDispatch();
  const { tokensData, tokenError } = useSelector((state) => state.tokens);
  // const scrollContainerRef = useRef(null);
  // const [isAtStart, setIsAtStart] = useState(true);
  // const [isAtEnd, setIsAtEnd] = useState(false);
  // const [filterVal, setFilterVal] = useState(0);

  // const { itemsData } = useSelector((state) => state.items);
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [tagFilter, setTagFilter] = useState("all");

  useEffect(() => {
    if (!tokensData?.length) {
      dispatch(fetchTokensData());
    } else {
      dispatch(setItemsData(tokensData));
      dispatch(updateTrendingCategoryItemData(tokensData));
    }
  }, [tokensData, dispatch]);
  // const handleScroll = (scrollAmount) => {
  //   const container = scrollContainerRef.current;
  //   if (container) {
  //     container.scrollBy({
  //       left: scrollAmount,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  // const checkScrollPosition = () => {
  //   const container = scrollContainerRef.current;
  //   if (container) {
  //     const isStart = container.scrollLeft === 0;
  //     const isEnd =
  //       Math.ceil(container.scrollLeft + container.clientWidth) >=
  //       container.scrollWidth;
  //     setIsAtStart(isStart);
  //     setIsAtEnd(isEnd);
  //   }
  // };

  // useEffect(() => {
  //   const container = scrollContainerRef.current;
  //   if (container) {
  //     container.addEventListener("scroll", checkScrollPosition);
  //     checkScrollPosition();

  //     return () => {
  //       container.removeEventListener("scroll", checkScrollPosition);
  //     };
  //   }
  // }, []);

  // const handleFilter = (category) => {
  //   setCategoryFilter(category);
  //   dispatch(updateTrendingCategoryItemData(itemsData));
  // };

  return (
    <div className="min-h-[40vh]">
      {/* <!-- Filter --> */}
      {/* <div
        className="flex items-center
gap-2 mb-8"
      >
        {!isAtStart && (
          <button
            className="p-2 bg-gray-300 rounded"
            onClick={() => handleScroll(-200)} // Adjust the scroll amount as needed
          >
            <svg
              width="26"
              height="25"
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="13.0871" cy="12.1911" r="12.1955" fill="white" />
              <path
                d="M14.4283 6.3322L8.56949 12.191L14.4283 18.0498L13.4401 19.0381L6.59302 12.191L13.4401 5.34397L14.4283 6.3322Z"
                fill="#111111"
              />
              <path
                d="M7.58118 12.897L7.58118 11.4852L19.5812 11.4852L19.5812 12.897L7.58118 12.897Z"
                fill="#111111"
              />
            </svg>
          </button>
        )}
        <div
          className="flex overflow-x-auto whitespace-nowrap scrollbar-thin items-start justify-between animate-scrollbar"
          ref={scrollContainerRef}
        >
          <ul className="flex items-center">
            {productDataFilter.map(({ id, svg, text }) => {
              // console.log("SVG = ", svg);
              if (text === "all") {
                return (
                  <li
                    className="my-1 mr-2.5 "
                    key={id}
                    onClick={() => {
                      handleFilter(text);
                      setFilterVal(id);
                    }}
                  >
                    <button
                      className={
                        filterVal === id
                          ? "btn-grad group text-white border border-transparent font-russo flex h-9 items-center justify-center rounded-lg px-4 text-sm font-semibold transition-all ease duration-300"
                          : "group border hover:bg-accent border-accent font-russo text-white flex h-9 items-center rounded-lg bg-black px-4 text-sm font-semibold hover:text-white transition-all hover:border-transparent ease duration-300"
                      }
                      onClick={() => handleFilter(text)}
                    >
                      ALL
                    </button>
                  </li>
                );
              } else {
                return (
                  <li
                    className="my-1 mr-2.5"
                    key={id}
                    onClick={() => {
                      handleFilter(text);

                      setFilterVal(id);
                    }}
                  >
                    <button
                      className={
                        filterVal === id
                          ? "btn-grad group my-0 mx-auto text-white font-russo flex h-9 items-center rounded-lg border px-6 text-sm font-semibold border-transparent transition-all ease duration-300"
                          : "my-0 mx-auto group hover:bg-accent hover:text-white border-[#8e0e00] font-russo text-white flex h-9 items-center rounded-lg border bg-black px-6 text-sm font-semibold hover:border-transparent transition-all ease duration-300"
                      }
                    >
                      <Image
                        src={`/images/svg/marketSvg/${svg}.svg`} // Adjust the path based on your folder structure
                        alt={`Icon ${svg}`}
                        width={4}
                        height={4}
                        className={
                          filterVal === id
                            ? "icon mr-1 h-4 w-4 transition-colors  fill-white text-white"
                            : "icon fill-white mr-1 h-4 w-4 transition-colors group-hover:fill-white text-white"
                        }
                      />
                      <span>{text}</span>
                    </button>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        {!isAtEnd && (
          <button
            className="p-2 bg-gray-300 rounded mr-5"
            onClick={() => handleScroll(200)} 
          >
            <svg
              width="26"
              height="25"
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="13.0871"
                cy="12.1912"
                r="12.1955"
                transform="rotate(180 13.0871 12.1912)"
                fill="white"
              />
              <path
                d="M11.7459 18.0501L17.6047 12.1913L11.7459 6.33248L12.7341 5.34424L19.5812 12.1913L12.7341 19.0384L11.7459 18.0501Z"
                fill="#111111"
              />
              <path
                d="M18.593 11.4854L18.593 12.8971L6.59302 12.8971L6.59302 11.4854L18.593 11.4854Z"
                fill="#111111"
              />
            </svg>
          </button>
        )}
        <UserDropdown setTagFilter={setTagFilter} />
        <Collection_dropdown />
      </div> */}

      {/* <!-- Grid --> */}
      <CategoryItem
        activeUserWallet={user?.walletAddress}
        wallet={wallet}
        filter={filter}
        categoryFilter={categoryFilter}
        balanceInfo={balanceInfo}
        userMap={userMap}
        tagFilter={tagFilter}
      />
    </div>
  );
};

export default Trending_categories_items;
